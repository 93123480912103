import React from "react";
import PropTypes from "prop-types";

const DropdownToggler = ({ opened, label }) => {
  return (
    <div className='dropwdown-toggler'>
      { label }
      <div
        className="filter-arrow opened"
        style={{
          transition: 'all .2s ease',
          transform: `rotate(${opened ? '0' : '180'}deg)`
        }}
      />
    </div>
  );
};

DropdownToggler.propTypes = {
  opened: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default DropdownToggler;
