import { globalHistory } from '@reach/router';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Tween } from 'react-gsap';
import { Background, Parallax } from 'react-parallax';
import { windowSizeChecker, replaceSubDomain } from '../../utils';

const PortfolioFull = ({ projectsList }) => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const isMobile = windowSizeChecker.isMobile();
  const [scale, setScale] = useState(1);

  const [lang, setLange] = useState('');

  useEffect(() => {
    setLange(globalHistory.location.pathname.split('/')[1]);
  }, []);

  const updateMousePosition = (e) => {
    e.persist();
    setMousePosition(() => {
      return {
        x: e.pageX - 30,
        y: e.pageY - 25,
      };
    });
  };

  const onPointerEnter = () => {
    setScale(1);
  };

  const onPointerLeave = () => {
    setScale(0);
  };

  //TODO: moving to dynamic
  const keys = {
    'Summer Catchers': 'summer-catchers',
    Shoutcart: 'shoutcart',
    Piclife: 'piclife',
    BSLGov: 'bsl-gov',
    Tradee: 'tradee',
    Citzbooking: 'citzbooking',
    'UFO Crusher': 'ufo-crusher',
    Buildup: 'buildup',
  };

  return (
    <>
      <div className="portfolio-container">
        <div
          className='portfolio'
        >
          <div className="portfolio-page-row">
            {projectsList.map(({image, title, description, id}) => (
              <div className='portfolio-item' key={id}>
                <div className="portfolio-image-wrap">
                  <Link
                    to={`/${lang}/portfolio/${keys[title]}`}
                    className="portfolio-image"
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={updateMousePosition}
                  >
                    <Parallax
                      bgImageAlt=""
                      strength={200}
                      bgImageSize="cover"
                      className="paralax-img-wide"
                      blur={0}
                    >
                      <Background className="background_wrapper">
                        <img
                          src={replaceSubDomain(image.desktop)}
                          alt=""
                        />
                      </Background>
                    </Parallax>
                  </Link>
                </div>
                <div className="portfolio-text">
                  <Link to={`/${lang}/portfolio/${keys[title]}`}>
                    <h2>{title}</h2>
                  </Link>
                  <p>{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Tween
        to={{
          x: mousePosition.x,
          y: mousePosition.y,
          scale: scale,
          opacity: scale,
        }}
        duration={0.3}
      >
        <div className="circle" />
      </Tween>
    </>
  );
};

export default PortfolioFull;
