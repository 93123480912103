import React, { useState } from 'react';
import { Accordion, AccordionCollapse, AccordionToggle } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import ContactUsAndThanks from '../components/CombineModals/ContactUsAndThanks';
import DropdownToggler from '../components/DropdownToggler';
import Layout from '../components/Layout';
import PortfolioFull from '../components/PortfolioFull';
import SEO from '../components/Seo';
import { typesFilter } from '../constants/typesFilters';
import { createMarkup } from '../helpers/createMarkup';
import FilterIcon from '../images/portfolio/filter-icon.svg';
import i18n from '../services/translation/i18next';
import { windowSizeChecker } from '../utils';

const Portfolio = ({ pageContext: { data } }) => {
  const { t } = useTranslation();
  const {isMobile} = windowSizeChecker
  const [projects, setProjects] = useState(data.project_item);
  const [activeFilter, setActiveFilter] = useState(typesFilter.ALL);
  const [isAccOpen, setAccOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = () => {
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
  };

  const filterProjects = (filter) => {
    const filteredProjects = data.project_item.filter(({filter_attribute}) => filter_attribute.includes(filter));
    setProjects(filteredProjects);
    setActiveFilter(filter);
  };

  const unfilterProjects = () => {
    setProjects(data.project_item);
    setActiveFilter(typesFilter.ALL);
  };

  const notNullTypes = () => {
    let filterTypes = t('portfolioPage.filters', { returnObjects: true });
    const existedTypes = Array.from(
      new Set(data.project_item.flatMap((b) => b.filter_attribute.split(', ')))
    );
    return filterTypes.filter(
      (item) =>
        existedTypes.includes(item) ||
        item === t('portfolioPage.selected', { returnObjects: true })
    );
  };

  const handleAccordion = () => {
    setAccOpen(!isAccOpen);
  };

  const styleChanger = (type) => {
    if (type === 'box') {
      if (isMobile()) return 'flex-md-column';
      return 'col-12';
    } else {
      if (isMobile()) return 'col-6';
      return 'col-3';
    }
  };

  return (
    <Layout>
      <SEO title="Projects" />
      <div className="project-page">
        <section className="section pb-0">
          <div className="container">
            <h1
              className="pageTitle pageTitlePortfolio"
              data-aos={'fade-up'}
              dangerouslySetInnerHTML={createMarkup(t('portfolioPage.title'))}
            />

            <div className="filter">
              <Accordion>
                <div className="filter-top">
                  <div className="filter-icon">
                    <img src={FilterIcon} alt="FilterIcon" />
                  </div>
                  <div className="text-gray-light">
                    <Trans i18nKey="portfolioPage.filter" />
                  </div>
                  <div className="text-gray-light mx-2">-</div>
                  <div>
                    <AccordionToggle
                      eventKey="0"
                      as="div"
                      className="btn border-0 p-0 text-primary filter-toggle"
                      onClick={handleAccordion}
                    >
                      <DropdownToggler
                        label={
                          activeFilter === 'all' ? (
                            <Trans i18nKey="portfolioPage.selected" />
                          ) : (
                            activeFilter
                          )
                        }
                        opened={isAccOpen}
                      />
                    </AccordionToggle>
                  </div>
                </div>
                <div className="filter-select ">
                  <AccordionCollapse eventKey="0">
                    <ul className={styleChanger('box')}>
                      {notNullTypes().map((item, id) => (
                        <li key={id} className={`${styleChanger('column')}`}>
                          {item === i18n.t('portfolioPage.selected') && (
                            <button
                              className={`btn btn-link p-0 ${
                                activeFilter === 'all' ? 'text-primary' : ''
                              }`}
                              onClick={unfilterProjects}
                            >
                              {item}
                            </button>
                          )}
                          {item !== i18n.t('portfolioPage.selected') && (
                            <button
                              className={`btn btn-link p-0 ${
                                activeFilter === item ? 'text-primary' : ''
                              }`}
                              onClick={() => filterProjects(item)}
                            >
                              {item}
                            </button>
                          )}
                        </li>
                      ))}
                    </ul>
                  </AccordionCollapse>
                </div>
              </Accordion>
            </div>
          </div>
        </section>
        {projects && (
          <PortfolioFull
            allProjectsLink={data.content_header.title}
            projectsList={projects}
          />
        )}
        <section className="section text-center pt-0 project-title">
          <div className="container">
            <div
              className="h1"
              dangerouslySetInnerHTML={createMarkup(
                t('portfolioPage.contactUs.title')
              )}
            />
            <div className="pt-3">
              <button
                className="btn btn-lg btn-primary rounded-pill mt-1"
                onClick={openModalHandler}
              >
                {t('portfolioPage.contactUs.btn')}
              </button>
            </div>
          </div>
        </section>
      </div>
      <ContactUsAndThanks show={isOpenModal} onHide={closeModalHandler} />
    </Layout>
  );
};

export default Portfolio;
